.column-nowrap {
  white-space: nowrap;
}

.styled-table {
  border-collapse: collapse;
  // margin: 0 -25px 0 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 345px;
  // box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #000;
  color: #fdee1f;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 5px 5px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #000;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #232424;
}

.split-pane-col {
  width: calc(100% - 0px);
  // height: calc(100vh - 0px);
  display: flex;
  flex-direction: column;
}

.split-pane-row {
  width: calc(100% - 0px);
  // height: calc(100vh - 0px);
  display: flex;
  flex-direction: row;
}

.split-pane-top,
.split-pane-bottom,
.split-pane-right,
.split-pane-left {
  flex: 1;
  overflow: hidden;
}

.split-pane-right {
  background-color: #fff;
  background-image: linear-gradient(90deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px), linear-gradient(#eee 0.1em, transparent 0.1em);
  background-size: 100% 1.2em;
}

.split-pane-top,
.split-pane-bottom {
  background-color: #ccc;
  text-align: left;
  border-width: 1px;
}

.separator-col {
  border: 5px solid black;
  cursor: col-resize;
}

.separator-row {
  border: 5px solid gray;
  cursor: row-resize;
}

.arrow-icon {
  width: 14px;
  height: 14px;
}

.arrow-icon > div {
  margin-left: -1px;
  margin-top: -3px;
  transform-origin: center center;
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.modal-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); /* for IE 9 */
  -webkit-transform: translate(-50%, -50%); /* for Safari */

  /* optional size in px or %: */
  width: 700px;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 899 !important;
}
